import * as React from 'react';
import Layout from '../../../components/layout';
import {StaticImage} from 'gatsby-plugin-image';
import OutboundLink from '../../../components/outboundlink';

const Book1ArtPage = () => {
  return (
    <Layout pagePath="/sourcesedge/artwork/book1">
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-2xl mb-8">
          The Secrets of Tercast Artwork
        </h1>
        <p className="mb-8">
          All works below completed by the immensely talented <OutboundLink href="https://www.artstation.com/tizianafr" className="font-bold text-blue-700" target="_blank" rel="noreferrer">
            Tiziana Federica Ruiu
          </OutboundLink>
        </p>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <StaticImage src="../../../images/art/delveton/delveton-concept-front-lightning2.jpg" alt="Delveton complete front"/>
          </div>
          <div>
            <StaticImage src="../../../images/art/wenkuth/wenkuth_armour_relaxed_03.jpg" alt="Wenkuth armor complete front"/>
          </div>
          <div>
            <StaticImage src="../../../images/art/ilkuth/ilkuth_armour_relaxed_03.jpg" alt="Ilkuth armor male complete front"/>
          </div>
          <div>
            <StaticImage src="../../../images/art/ilkuth/ilkuth_armour_relaxed_08.jpg" alt="Ilkuth armor female complete front"/>
          </div>
          <div>
            <StaticImage src="../../../images/art/zelyra/zelyra-concept-front.jpg" alt="Zelyra concept front"/>
          </div>
        </div>
      </div> 
    </Layout>
  )
};

export default Book1ArtPage;